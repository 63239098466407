
import { defineComponent, ref, onMounted } from 'vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';

export default defineComponent({
  name: 'IndicadorNumero',
  props: {
    cor: {
      type: String,
      default: '',
    },
    titulo: {
      type: String,
    },
    monetario: {
      type: Boolean,
      default: false,
    },
    valor: {
      type: Number,
      default: 0,
    },
    casasDecimais: {
      type: Number,
      default: 2,
    },
  },
  setup(props) {
    const displayValue = ref(0);

    const animateValue = (end: number, duration: number) => {
      const stepTime = Math.abs(Math.floor(duration / end));
      let current = 0;

      const increment = setInterval(() => {
        current += 1;
        displayValue.value = current;

        if (current >= end) {
          clearInterval(increment);
        }
      }, stepTime);
    };

    onMounted(() => {
      if (props.valor > 0) {
        animateValue(props.valor, 2000); // 2000ms = 2 segundos
      } else {
        displayValue.value = props.valor;
      }
    });

    return {
      props,
      UtilitarioMascara,
      displayValue,
    };
  },
});
