import ApiCommerce from '@/core/apis/ApiCommerce';
import { IDTODashboardIndicadores } from '@/models/DTO/Dashboard/IDTODashboardIndicadores';

class ServicoDashboard {
    private endPoint = 'dashboard';

    private apiCommerce = new ApiCommerce();

    public async obterIndicadoresDashBoard(): Promise<IDTODashboardIndicadores> {
      const result: any = await this.apiCommerce.get(`${this.endPoint}/indicadores`);
      return result.data;
    }
}
export default ServicoDashboard;
