import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8da8af92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-card" }
const _hoisted_2 = { class: "ui-card-body ui-indicador-numero" }
const _hoisted_3 = { class: "ui-titulo-indicador-numero" }
const _hoisted_4 = {
  key: 0,
  class: "ui-valor-indicador-numero"
}
const _hoisted_5 = {
  key: 1,
  class: "ui-valor-indicador-numero"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.props.titulo), 1),
      (_ctx.props.monetario)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.UtilitarioMascara.mascararValor(_ctx.displayValue, _ctx.props.casasDecimais, false, true)), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.UtilitarioMascara.mascararNumeroInteiro(_ctx.displayValue)), 1))
    ])
  ]))
}